import React, { useLayoutEffect, useMemo } from "react";
import { Badge, BlockStack, Card, Divider, InlineStack, Page, Text, useBreakpoints } from "@shopify/polaris";
import { AlertCircleIcon as CircleAlertMajor, StatusActiveIcon as CircleTickMinor, EmailIcon as EmailMajor } from "@shopify/polaris-icons";
import { useRef, useState } from "react";
import { EventEmitter, useAngular, useAsyncEffect, useObservable } from "react-utils";
import { useSimpleValueStore } from "./react-app/useSimpleValueStore";
import { ChoiceListInlineStack, formatUserInput, SimpleValueStoreSection, titlebody, titlelink, SettingsSection } from "./react-app/fields";
import { CreditCardParser } from "./react-app/CreditCardParser";
import { AppService } from "./service-app";
import { useLoaderData, useNavigate } from "react-router";
import { firstValueFrom } from "rxjs";
import { ok } from "common";
import { AuthService } from "./service-auth";
import { useCardStore } from "./useCardStore";
import { asCustomer } from "../flags";


const formatExpiry = (value: string) => {
  value = value.split("/").join("");
  return value.slice(0, 2) + (value.length > 2 ? "/" : "") + value.slice(2);
}

export default function PageBilling() {

  const { get } = useAngular();
  const app = get(AppService);
  const auth = get(AuthService);


  const { status, statusChange } = app;
  const navigate = useNavigate();

  useObservable(statusChange);

  useLoaderData();

  ok(status);

  const contact = new useSimpleValueStore({
    Name: status.Name ?? "",
    Address: status.Address ?? "",
    Phone: status.Phone ?? "",
  }, async (value) => {
    const { Name, Phone, Address } = value;
    await app.postAppState("basic-info", { Name, Address, Phone });
    await app.updateStatus();
  });

  const { action } = contact;

  useLayoutEffect(() => {
    if (status) action("reset");
  }, [action, status]);

  const { StorageAgreementStatus } = status;

  const storageAgreementMarkup =
    !status.EmailVerified ? <Badge size="large" icon={EmailMajor}>Verify Email</Badge> :
      StorageAgreementStatus === "completed" ? <Badge size="large" tone="success" icon={CircleTickMinor}>Completed</Badge> :
        <Badge size="large" tone="attention" icon={CircleAlertMajor}>Signature Required</Badge>


  const { mdDown } = useBreakpoints();

  const payments = useCardStore();


  return (
    <Page fullWidth={mdDown}>
      <SettingsSection.Wrapper>
        <SettingsSection title="Account Settings">
          {titlebody("Email", status.Email)}
          {auth.canChangePassword && titlelink("Password", "Reset password", () => { navigate("/change-password"); })}
          {asCustomer && <BlockStack gap="100">
            <Text as="h4" variant="headingMd">Password</Text>
            <p><strong>Branch:</strong> There is a button here which the customer can use to change their password.
              It is only shown if the customer logged in with their username and password, not if they logged in with Google.</p>
          </BlockStack>}
          {titlebody("Storage Agreement", <div>{storageAgreementMarkup}</div>)}
        </SettingsSection>
        <Divider />
        <SimpleValueStoreSection store={contact} title="Contact Info">
          {contact.TextField({ key: "Name", title: "Name" })}
          {contact.AddressLookup({
            key: "Address",
            title: "Address",
            onSearch: async (input) => {
              const res = await app.postAppState("address-lookup", { input });
              return (res ?? []).map((e: any) => ({ value: e.description, label: e.description }));
            }
          })}
          {contact.TextField({ key: "Phone", title: "Phone number" })}
        </SimpleValueStoreSection>
        <Divider />
        <SimpleValueStoreSection store={payments.store} title="Payment Info">
          {payments.markup}
        </SimpleValueStoreSection>
      </SettingsSection.Wrapper>
    </Page>
  );
}
