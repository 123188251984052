import "./index.scss";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import "@angular/compiler";
import awsConfig from './aws-config.json';
import { Amplify } from 'aws-amplify';
import { AccountSettings, Authenticator, AuthenticatorProps, Card, Flex, Message, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { confirmResetPassword, confirmSignIn, confirmSignUp, resetPassword, signIn, signUp } from "aws-amplify/auth";
import { Hub } from 'aws-amplify/utils';
import { CUBES_LOGO, StorageAgreement } from "common";
import AppLayout, { ErrorBoundary } from "./frame";
import { i18n, rootInjector, useAngular, useEventEmitter, useObservable, useObserver } from "react-utils";
import { AuthService, SignoutContext } from "./service-auth";
import { Router } from "@angular/router";
import { AppProvider, Badge, Modal, Page } from "@shopify/polaris/index";
import { createBrowserRouter, createRoutesFromElements, isRouteErrorResponse, Link, Route, RouterProvider, useRevalidator, useRouteError } from "react-router-dom";
import { LinkLikeComponentProps } from "@shopify/polaris/utilities/link";
import Billing from "./page-billing";
import Ledger from "./page-ledger";
import Frame from "./frame";
import { AppService } from "./service-app";
import { distinct, filter, firstValueFrom } from "rxjs";
import VerifyEmail from "./page-verify-email";
import FinishTokenId from "./service-token-id";
import ChangePassword from "./page-change-password";
import GettingStarted from "./page-getting-started";
import { StorageAgreementLink, StorageAgreementResult } from "./service-storage-agreement";
import { asCustomer } from "../flags";


const prodsite = location.origin === "https://account.getcubesstorage.com";
if (!asCustomer) {

  Hub.listen('auth', (data) => { console.log("Hub.auth", data) });
  awsConfig.auth.oauth.redirect_sign_in_uri[0] = location.origin + "/";
  awsConfig.auth.oauth.redirect_sign_out_uri[0] = location.origin + "/";
  awsConfig.auth.user_pool_client_id = prodsite ? "ednohrq0h1k2dago2gcv6hku1" : "q7n4s8af6b7thfcur95r3vjot";
  Amplify.configure(awsConfig);
  // console.log(awsConfig);
}

type t1 = AuthenticatorProps["services"] & {};

rootInjector.provide(Router,
  new Proxy({}, {
    get: (target, prop) => {
      throw new Error("We aren't using router in customer");
    }
  })
);


rootInjector.provide(AuthService, new AuthService(rootInjector));

function withAsyncLog<T extends (...args: any) => any>(fn: T): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
  return async function (this: any, ...args: any[]) {
    const res = await fn.apply(this, args);
    console.log(fn.name, args, res);
    return res;
  }
}

class Auth2 implements t1 {
  // handleSignIn: typeof signIn = withAsyncLog(signIn);
  // handleSignUp: typeof signUp = withAsyncLog(signUp);
  // handleConfirmSignIn: typeof confirmSignIn = withAsyncLog(confirmSignIn);
  // handleConfirmSignUp: typeof confirmSignUp = withAsyncLog(confirmSignUp);
  // handleForgotPasswordSubmit: typeof confirmResetPassword = withAsyncLog(confirmResetPassword);
  handleForgotPassword: typeof resetPassword = async (input) => {

    const url = new URL(location.href);
    const email = url.searchParams.get("email");
    const code = url.searchParams.get("code");

    if (email && code) {
      if (input.username !== email) console.log("email mismatch", input.username, email);
      return {
        isPasswordReset: false,
        nextStep: {
          resetPasswordStep: 'CONFIRM_RESET_PASSWORD_WITH_CODE',
          codeDeliveryDetails: {
            deliveryMedium: "EMAIL",
            destination: email.split("@").map(e => e[0] + "***").join("@"),
            attributeName: "email",
          },
        },
      };
    }

    return await resetPassword(input);

  }
}

const auth2 = new Auth2();

const titles = {
  verifyUser: "Account recovery requires verified contact information",
  confirmVerifyUser: "Account recovery requires verified contact information",
  forgotPassword: "Reset Password",
  confirmResetPassword: "Reset Password",
}

const header = (key: keyof typeof titles) => <span className="amplify-heading amplify-heading--3">{titles[key]}</span>
const subtext = (hasCode: boolean) => <span className="amplify-text amplify-text--primary">{subtext1[+hasCode]}</span>
const subtext1 = [
  "We emailed you a code and a link. You can either enter the code in the box below or click on the link in the email.",
  "Your code has been entered below. Please enter your new password.",
];

export default function App() {

  const auth = useAngular().get(AuthService);
  const app = useAngular().get(AppService);


  if (asCustomer) {
    useEffect(() => {
      auth.setStatus("authenticated", "authenticated", asCustomer);
    }, [auth]);
    return <AppInner key={asCustomer} />;
  }

  const {
    authStatus,
    route,
    user,
    skipVerification,
    toForgotPassword,
    submitForm,
    updateForm,
    signOut,
  } = useAuthenticator(context => [
    context.authStatus,
    context.route,
    context.user,
    context.skipVerification,
    context.toForgotPassword,
    context.submitForm,
    context.updateForm,
    context.signOut,
  ]);

  const [codeEntered, setCodeEntered] = React.useState(false);

  const routeUpdates = useEventEmitter();
  useObserver(routeUpdates, useCallback(() => {
    const url = new URL(location.href);
    const email = url.searchParams.get("email");
    const code = url.searchParams.get("code");
    if (route === "signIn" && url.pathname === "/forgot-password" && email && code) {
      toForgotPassword();
    }
    if (route === "forgotPassword" && email && code) {
      updateForm({ name: "username", value: email });
      submitForm();
    }
    if (route === "confirmResetPassword" && email && code) {
      updateForm({ name: "confirmation_code", value: `${code}` });
      (document.forms[0][1] as HTMLInputElement).value = `${code}`;
      setCodeEntered(true);
    }
    if (route === "verifyUser") {
      // we skip verification here because it's just the code and we can do that via API
      skipVerification();
    }
  }, [route, skipVerification, submitForm, toForgotPassword, updateForm]));

  useLayoutEffect(() => { routeUpdates.emit(route); }, [route, routeUpdates]);

  useLayoutEffect(() => {
    auth.setStatus(authStatus, route, user?.userId);
  }, [auth, authStatus, route, user?.userId]);

  useObservable(app.statusChange);

  console.log(route, app.status);
  const signOut2 = useCallback(() => {
    app.loginToken = undefined;
    app.status = undefined;
    app.statusChange.emit(undefined);
    signOut();
  }, [signOut, app]);

  return (<>
    <div attr-prodsite={prodsite.toString()}></div>
    <Authenticator hideSignUp services={auth2} loginMechanism="email" components={{
      Header: () => <>
        <img src={CUBES_LOGO} alt="Cubes Storage" style={{
          width: "100%",
          padding: "2rem",
          boxSizing: "border-box",
        }} />
      </>,
      ConfirmResetPassword: {
        Header: () => <>
          {header("confirmResetPassword")}
          {subtext(codeEntered)}
        </>,
      },
      ConfirmVerifyUser: {
        Header: () => <>
          {header("confirmVerifyUser")}
          {subtext(codeEntered)}
        </>
      },
      SignIn: {
        Header: () => <>
          {app.loginToken ? <Message
            variation="filled"
            colorTheme="info"
            heading="Email Token Detected"
            margin="xl"
          >
            Please login to continue
          </Message> : null}
        </>,
      }
    }} />
    <SignoutContext.Provider value={signOut2} >
      <AppInner key={auth.userId} />
    </SignoutContext.Provider>
  </>);
}

const AppInner = () => (
  <AppProvider linkComponent={AppLinks} features={{ polarisSummerEditions2023ShadowBevelOptOut: true }} i18n={i18n}>
    <RouterProvider router={router} />
  </AppProvider>
);

export function AppLinks({ url, ...rest }: LinkLikeComponentProps) {
  return <Link to={url} {...rest} />;
}

const router = createBrowserRouter(
  createRoutesFromElements(<>
    <Route path="/" element={<Frame />} >
      <Route errorElement={<ErrorBoundary />} >
        <Route path="getting-started" element={<GettingStarted />} />
        <Route path="billing" element={<Billing />} index />
        <Route path="ledger" element={<Ledger />} />
        <Route path="update-payment-method" element={<FinishTokenId />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="storage-agreement" element={<StorageAgreementLink />} />
      </Route>
    </Route>
    <Route path="/storage-agreement-result" element={<StorageAgreementResult />} />
  </>)
);

const t = [
  // forgot password
  {
    "version": "1",
    "triggerSource": "CustomEmailSender_ForgotPassword",
    "region": "us-east-2",
    "userPoolId": "us-east-2_7Gjce0SlN",
    "userName": "4c8386fe-cadb-47dc-a2b7-8c8d345008b8",
    "callerContext": {
      "awsSdkVersion": "aws-sdk-js-2.1639.0",
      "clientId": null
    },
    "request": {
      "type": "customEmailSenderRequestV1",
      "code": "355657",
      "clientMetadata": null,
      "userAttributes": {
        "sub": "4c8386fe-cadb-47dc-a2b7-8c8d345008b8",
        "cognito:email_alias": "arlenbee+test24@gmail.com",
        "cognito:user_status": "CONFIRMED",
        "email_verified": "true",
        "email": "arlenbee+test24@gmail.com"
      }
    }
  },
  //force reset password
  {
    "version": "1",
    "triggerSource": "CustomEmailSender_ForgotPassword",
    "region": "us-east-2",
    "userPoolId": "us-east-2_7Gjce0SlN",
    "userName": "4c8386fe-cadb-47dc-a2b7-8c8d345008b8",
    "callerContext": {
      "awsSdkVersion": "aws-sdk-unknown-unknown",
      "clientId": "q7n4s8af6b7thfcur95r3vjot"
    },
    "request": {
      "type": "customEmailSenderRequestV1",
      "code": "473016",
      "clientMetadata": null,
      "userAttributes": {
        "sub": "4c8386fe-cadb-47dc-a2b7-8c8d345008b8",
        "cognito:email_alias": "arlenbee+test24@gmail.com",
        "cognito:user_status": "CONFIRMED",
        "email_verified": "true",
        "email": "arlenbee+test24@gmail.com"
      }
    }
  },
  // verify after login
  {
    "version": "1",
    "triggerSource": "CustomEmailSender_VerifyUserAttribute",
    "region": "us-east-2",
    "userPoolId": "us-east-2_7Gjce0SlN",
    "userName": "4c8386fe-cadb-47dc-a2b7-8c8d345008b8",
    "callerContext": {
      "awsSdkVersion": "aws-sdk-unknown-unknown",
      "clientId": "q7n4s8af6b7thfcur95r3vjot"
    },
    "request": {
      "type": "customEmailSenderRequestV1",
      "code": "713961",
      "clientMetadata": null,
      "userAttributes": {
        "sub": "4c8386fe-cadb-47dc-a2b7-8c8d345008b8",
        "cognito:email_alias": "arlenbee+test24@gmail.com",
        "cognito:user_status": "CONFIRMED",
        "email_verified": "false",
        "email": "arlenbee+test24@gmail.com"
      }
    }
  }
];