import { useNavigate } from "react-router";
import { useAngular, useAsyncEffect } from "react-utils";
import { AppService } from "./service-app";
import { Modal } from "@shopify/polaris/index";
import { useCallback, useState } from "react";
import { is, ok } from "common";
import { useAuthenticator } from "@aws-amplify/ui-react";


export function StorageAgreementLink() {
  // we can't depend on the customer being logged in
  const { get } = useAngular();
  const navigate = useNavigate();
  const app = get(AppService);

  const [loading, setLoading] = useState(true);
  const [complete, setComplete] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const doClose = useCallback(() => { navigate("/billing"); }, [navigate]);
  useAsyncEffect(async () => {
    if (complete || !app.loginToken || !app.loginToken.key || !app.loginToken.token || !app.loginToken.sig) return;
    ok(is<{ key: string, token: string, sig: string }>(app.loginToken, true));
    const [error, res] = await app.postAppState("get-storage-agreement-link", { ...app.loginToken, sendEmail: false }).try();
    if (error) {
      setMessage(error.message);
    } else if (res === "SENT_TO_EMAIL") {
      // this shouldn't happen with sendEmail false
    } else if (res === "EMAIL_TOKEN_INVALID") {
      setMessage("This link is invalid. We're not sure what happened, but you can try sending the link again.");
    } else if (res?.url){
      location.href = res.url;
      return;
    }
    setLoading(false);
    app.loginToken = undefined;
  }, undefined, undefined, [complete, app.loginToken?.key, app.loginToken?.token, app.loginToken?.sig]);

  // if (loading) return null;

  return <>
    <Modal
      open={true}
      onClose={() => { }}
      title="Storage Agreement"
      primaryAction={{ content: 'Ok', onAction: doClose, loading }}
    >
      <Modal.Section>
        {loading && <p>Confirming email link, redirecting to storage agreement...</p>}
        {message && <p>{message}</p>}
      </Modal.Section>
    </Modal>
  </>
}



export function StorageAgreementResult() {
  // we can't depend on the customer being logged in

  const { get } = useAngular();
  const navigate = useNavigate();
  const app = get(AppService);

  // asCustomer doesn't matter here because a branch shouldn't ever see this page.
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const res = app.storageAgreementResult;

  const isLoggedIn = authStatus === "authenticated";
  // const [loading, setLoading] = useState(true);
  // const [complete, setComplete] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [message, setMessage] = useState("");
  const { loading, result: success } = useAsyncEffect(async () => {
    const [error1] = await app.postAppState("check-storage-agreement", {}).try();
    const [error2] = await app.updateStatus().try();
    return !error1 && !error2;
  });

  const onClose = useCallback(async () => {
    navigate("/billing");
  }, [navigate]);

  if (res?.incomplete && isLoggedIn) {
    onClose();
    return null;
  }

  return <>
    <Modal
      open={true}
      onClose={() => { }}
      title="Storage Agreement Result"
      primaryAction={{ content: 'Ok', onAction: onClose, loading }}
      loading={!res}
    >
      {res && <Modal.Section >
        {res.complete && <p>Thank you for signing the storage agreement.</p>}
        {res.timed_out && <p>The storage agreement has to be signed within 20 minutes or the session starts over. You can just open it again.</p>}
        {res.fax && <p>The result code says something about a fax.</p>}
        {res.error && <p>An error occured. Please try again or contact us.</p>}
        {res.incomplete && <p>You did not sign the storage agreement.</p>}
        {loading && <p>Please wait...</p>}
        {success && <p>Your storage agreement status has been updated.</p>}
      </Modal.Section>}
    </Modal>
  </>
}
