import { AccountSettings } from "@aws-amplify/ui-react";
import { Box, Modal } from "@shopify/polaris/index";
import { useNavigate } from "react-router";
import { MessageService, useAngular } from "react-utils";


export default function ChangePassword() {
  const navigate = useNavigate();
  // we reload the page so that lastpass guesses we succeeded.
  return <Modal open title="Change Password" onClose={() => { navigate("/billing") }}>
    <Box padding="400"><AccountSettings.ChangePassword onSuccess={() => { location.href = location.origin + "/billing" }} /></Box>
  </Modal>
}