
import { asCustomer } from './flags';
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './app'
import { Authenticator } from '@aws-amplify/ui-react'
import { NgContextProvider, rootInjector } from 'react-utils'
import { LinkLikeComponentProps } from '@shopify/polaris/utilities/link'
import { Link } from 'react-router-dom'

export function AppLinks({ url, ...rest }: LinkLikeComponentProps) {
  return <Link to={url} {...rest} />;
}


const children = (
  <NgContextProvider injector={rootInjector}>
    <App />
  </NgContextProvider>
)
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    {asCustomer ? children : <Authenticator.Provider>{children}</Authenticator.Provider>}
  </StrictMode>,
)
