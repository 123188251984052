
import React, { useLayoutEffect, useMemo } from "react";
import { Badge, Card, Divider, InlineStack, Page, useBreakpoints } from "@shopify/polaris";
import { AlertCircleIcon as CircleAlertMajor, StatusActiveIcon as CircleTickMinor, EmailIcon as EmailMajor } from "@shopify/polaris-icons";
import { useRef, useState } from "react";
import { EventEmitter, useAngular, useAsyncEffect, useObservable } from "react-utils";
import { useSimpleValueStore } from "./react-app/useSimpleValueStore";
import { ChoiceListInlineStack, formatUserInput, SimpleValueStoreSection, titlebody, titlelink, SettingsSection } from "./react-app/fields";
import { CreditCardParser } from "./react-app/CreditCardParser";
import { AppService } from "./service-app";
import { useLoaderData, useNavigate } from "react-router";
import { firstValueFrom } from "rxjs";
import { ok } from "common";
import { AuthService } from "./service-auth";
import { asCustomer } from "../flags";

export type contactkeys = "Name" | "Address" | "Phone number";
export type contactinfo = Partial<Record<contactkeys, string>>;

export type cardkeys = "Card Number" | "Expiry Date";
export type cardinfo = Partial<Record<cardkeys, string>>;

const formatExpiry = (value: string) => {
  value = value.split("/").join("");
  return value.slice(0, 2) + (value.length > 2 ? "/" : "") + value.slice(2);
}


const accountChoices = [{ label: "Checking", value: "checking" }, { label: "Savings", value: "savings" }];
const entityChoices = [{ label: "Personal", value: "personal" }, { label: "Business", value: "business" }];

export function useCardStore() {

  const { get } = useAngular();
  const app = get(AppService);
  const { status, statusChange } = app;
  ok(status);
  const card = new useSimpleValueStore({
    "billing-cc-number": "",
    "billing-cc-exp": "",
    "billing-cc-cvv": "",
    "billing-cc-autopay": "true",
    "billing-account-name": "",
    "billing-routing-number": "",
    "billing-account-number": "",
    "billing-account-type": "",
    "billing-entity-type": "",
  }, async (value) => {
    if (asCustomer) {
      return void alert(
        "Clicking this saves the customer's payment information. " +
        "This is disabled when viewing the page as a branch."
      );
    }
    const { "billing-cc-autopay": autopay } = value;
    await app.postAppState("autopay-info", { autopay: method === "cc" && autopay === "true" });
    cardform.current?.submit();
    // this causes the page to navigate, so we just return a never ending promise
    return new Promise(resolve => { });
  });

  const cardform = useRef<HTMLFormElement | null>(null);

  const [method, setMethod] = useState("cc");

  const curFormUrl = !asCustomer ? useFormUrl(card) : "";

  const type = status.flags[0] as "c" | "k" | "n";

  const isCard = type === "c";
  const isBank = type === "k";


  const markup = <>
    {!asCustomer && <form
      ref={cardform}
      action={curFormUrl}
      method="post"
    >{Object.entries(card.curValue).map(e => e[0] === "billing-cc-autopay" ? null : (
      <input
        type="hidden"
        name={e[0]}
        value={e[0] === "billing-routing-number" ? e[1].split(" ").join("") : e[1]}
      />
    ))}</form>}
    {asCustomer && <p><strong>Branch:</strong> You are viewing the page as a branch, so you can't save changes here, but you can still see how it works.</p>}
    {!card.editing ? [
      isCard ? titlebody("Card Number", status.ccNumber) : null,
      isCard ? titlebody("Expiry Date", status.ccExpiry) : null,
      isCard ? (<div>{
        status.AutoPay
          ? <Badge progress="complete" tone="success" size="large">Autopay Enabled</Badge>
          : <Badge progress="incomplete" size="large">Autopay Disabled</Badge>
      }</div>) : null,
      isBank ? titlebody("Routing Number", status.ckRouting) : null,
      isBank ? titlebody("Account Number", status.ckAccount) : null,
      isBank ? <Badge progress="incomplete" size="large">Autopay Disabled</Badge> : null,
    ] : <>
      {card.editing ? <ChoiceListInlineStack
        label="Payment Method"
        choices={[{ label: 'Credit Card', value: 'cc' }, { label: 'Bank Transfer', value: 'ck' },]}
        selected={[method]}
        onChange={(value) => {
          ok(Array.isArray(value) && value.length === 1);
          setMethod(value[0]);
          card.action("reset");
        }}
      /> : null}
      {method === "cc" ? card.TextField({
        required: true,
        key: "billing-cc-number",
        title: "Card Number",
        onChangeMap: (value) => formatUserInput(value),
        validate: ({ "billing-cc-number": value }) => {
          return (!CreditCardParser.checkCardNumber(value.split(" ").join(""))) ? "Invalid card number" : "";
        }
      }) : null}
      <InlineStack align="start" gap="300">
        {method === "cc" ? card.TextField({
          key: "billing-cc-exp", title: "Expiry Date", onChangeMap: formatExpiry, required: true,
          validate: ({ "billing-cc-exp": value }) => {
            return (!CreditCardParser.checkExpiration(value)) ? "Invalid expiration date" : "";
          }
        }) : null}
        {method === "cc" ? card.TextField({
          required: true,
          key: "billing-cc-cvv",
          title: "CVV",
          validate: ({ "billing-cc-cvv": cvv, "billing-cc-number": ccn }) => {
            return (!CreditCardParser.checkCVV(ccn.split(" ").join(""), cvv)) ? "Invalid code" : "";
          }
        }) : null}
      </InlineStack>
      {method === "cc" ? card.Checkbox({
        key: "billing-cc-autopay",
        title: "Enable Auto-Pay",
        helptext: "Automatically charge this card when a unit rental is due.",
        trueValue: "true",
        falseValue: "false",
      }) : null}
      {method === "ck" ? card.TextField({
        key: "billing-account-name",
        title: "Name on Check",
        required: true,
      }) : null}

      <InlineStack align="start" gap="300">
        {method === "ck" ? card.TextField({
          key: "billing-routing-number",
          title: "Routing Number",
          onChangeMap: (value) => formatSpacing(value, 3),
          required: true
        }) : null}
        {method === "ck" ? card.TextField({
          key: "billing-account-number",
          title: "Account Number",
          required: true
        }) : null}
      </InlineStack>

      <InlineStack align="start" gap="300">
        {method === "ck" ? card.ChoiceListInline({ key: "billing-account-type", title: "Account Type", choices: accountChoices, required: true }) : null}
        {method === "ck" ? card.ChoiceListInline({ key: "billing-entity-type", title: "Entity Type", choices: entityChoices, required: true }) : null}
      </InlineStack>
      {method === "ck" ? <p>
        We do not use autopay for bank accounts.
        You will need to make payments yourself through this portal
        or contact your account representative to run payments for you.
      </p> : null}
    </>}

  </>;
  return { store: card, markup };

}


function useFormUrl(card: useSimpleValueStore<any>) {
  const app = useAngular().get(AppService);
  const [curFormUrl, setFormUrl] = useState("");
  const [curFormUrlLoading, setFormUrlLoading] = useState(false);
  useAsyncEffect(async () => {
    // console.log("loadFormUrl", !!app.status, !!app.idToken);

    if (!app.status) return;

    if (app.paymentFormTokenId) return;

    if (curFormUrl || curFormUrlLoading) return;

    if (!card.editing) return;

    setFormUrlLoading(true);

    const [error, res] = await app.postAppState("form-url", { redirect: location.href }).then(e => [undefined, e], e => [e, undefined]);

    const { formUrl } = res ?? {};

    setFormUrlLoading(false);

    if (formUrl && !error) setFormUrl(formUrl);

  }, undefined, undefined, [card.editing, app.status, app.paymentFormTokenId, curFormUrl, curFormUrlLoading]);
  return curFormUrl;
}

function formatSpacing(input: string, spacing: number) {
  return input.split(" ").join("").replace(new RegExp(`(.{${spacing}})`, "g"), "$1 ").trim();
}
