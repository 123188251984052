import React, { useLayoutEffect, useMemo } from "react";
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Divider, InlineStack, Modal, Page, TextField, useBreakpoints } from "@shopify/polaris";
import { AlertCircleIcon as CircleAlertMajor, StatusActiveIcon as CircleTickMinor, EmailIcon as EmailMajor } from "@shopify/polaris-icons";
import { useRef, useState } from "react";
import { ButtonAwait, CardTitle, ConfirmationService, EventEmitter, useAngular, useAsyncEffect, useObservable } from "react-utils";
import { useSimpleValueStore } from "./react-app/useSimpleValueStore";
import { ChoiceListInlineStack, formatUserInput, SimpleValueStoreSection, titlebody, titlelink, SettingsSection, saveButtons } from "./react-app/fields";
import { CreditCardParser } from "./react-app/CreditCardParser";
import { AppService } from "./service-app";
import { useLoaderData, useNavigate } from "react-router";
import { firstValueFrom } from "rxjs";
import { ok } from "common";
import { AuthService } from "./service-auth";
import { useCardStore } from "./useCardStore";

export default function PageGettingStarted() {

  const { get } = useAngular();
  const app = get(AppService);

  const { status, statusChange } = app;

  ok(status);

  const {
    Email,
    EmailVerified,
    StorageAgreementCompleted,
    valid: PaymentInfoValid,
    Name, Address, Phone,
  } = status;

  const validname = (Name ?? "").trim().length > 0;

  useObservable(statusChange);

  useLoaderData();

  ok(status);

  const [emailResent, setEmailResent] = useState(false);

  useObservable(app.customerVerifiedChange);

  const onClickResendEmail = async () => {
    await app.postAppState("send-email-link", {});
    setEmailResent(true);
  };



  const basicInfoMarkup = useBasicInfoMarkup(app, async () => {
    // updateStatus is called right before this
    if (!app.status) return;
    const customerVerifiedHere = JSON.parse(app.customerVerified ?? "null")
    ok(customerVerifiedHere);
    const { token, sig, key } = customerVerifiedHere;
    ok(token);
    ok(sig);
    const emailValid = app.status.EmailVerified;
    const nameValid = (app.status.Name ?? "").trim().length > 0;

    if (emailValid && nameValid && !app.status.StorageAgreementStatus) {
      // try generating the storage agreement again
      await app.postAppState("verify-email", { token, sig, key });
    }
  });

  const storage = useStorageAgreementModal();
  const payment = useCardStore();
  useLayoutEffect(() => {
    if (!app.status) return;
    if (!EmailVerified) return;
    if (!validname) return;
    if (!StorageAgreementCompleted) return;
    if (PaymentInfoValid) return;
    if (!payment.store.editing) payment.store.action("edit");
  }, [app.status, payment.store, EmailVerified, validname, StorageAgreementCompleted, PaymentInfoValid]);

  return (
    <Page fullWidth>
      <BlockStack gap="400" inlineAlign="center">
        <Box maxWidth="32rem" width="100%">
          <BlockStack gap="400" inlineAlign="stretch">
            <p>Welcome. This page walks you through the steps you need to take to get started.</p>
            <CardTitle title={<div><strong>Email: </strong>{Email}</div>} padding="0"></CardTitle>
            {EmailVerified
              ? <Badge size="large" tone="success" icon={CircleTickMinor}>Email address verified</Badge>
              : <Badge size="large" icon={EmailMajor}>Verify your email</Badge>}
            {!EmailVerified && <>
              <p>We've sent you an email with a link to confirm your email address.</p>
              <p>Can't find it? <ButtonAwait onClick={onClickResendEmail}>Send it again</ButtonAwait></p>
              {emailResent && <p>Check your email for the new link</p>}
            </>}
            {EmailVerified && <>
              <CardTitle title="Contact Info" padding="0"></CardTitle>
              {!validname ? <>
                {basicInfoMarkup}
              </> : <>
                <Badge size="large" tone="success" icon={CircleTickMinor}>Contact Info entered</Badge>
              </>}
            </>}
            {(EmailVerified && validname) && <>
              <CardTitle title="Storage Agreement" padding="0"></CardTitle>
              {storage.pageMarkup}
            </>}

            {(EmailVerified && validname && StorageAgreementCompleted) && <>
              <CardTitle title="Payment Info" padding="0"></CardTitle>
              {!PaymentInfoValid ? <>
                {payment.markup}
                {saveButtons(payment.store, false)}
              </> : <>
                <Badge size="large" tone="success" icon={CircleTickMinor}>Payment Info entered</Badge>
              </>}
            </>}
            <Divider />
            {/* {statusMarkup} */}
          </BlockStack>
        </Box>
      </BlockStack>
      {storage.modalMarkup}
    </Page>
  );
}

function useStorageAgreementModal() {
  const { get } = useAngular();
  const app = get(AppService);
  const status = app.status;
  ok(status);
  const { StorageAgreementCompleted } = status;
  const [agreementSentToEmail, setAgreementSentToEmail] = useState(false);


  const [openModal, setOpenModal] = useState(false);

  const onClickSignStorageAgreement = async () => {
    const { key, token, sig } = JSON.parse(app.customerVerified ?? "{}");
    const res = await app.postAppState("get-storage-agreement-link", { key, token, sig, sendEmail: true });
    switch (res) {
      case "SENT_TO_EMAIL": {
        setAgreementSentToEmail(true);
        setOpenModal(true);
        break;
      }
      case "EMAIL_TOKEN_INVALID": {
        // shouldn't happen if sendEmail is true
        break;
      }
      default: {
        console.log(res?.url);
        if (res?.url) location.href = res.url;
      }
    }
  };

  const onClose = () => { setOpenModal(false); };

  return {
    //==================================
    modalMarkup: !StorageAgreementCompleted && (
      <Modal
        open={openModal}
        onClose={onClose}
        title="Storage Agreement"
        primaryAction={{ content: 'Ok', onAction: onClose }}
      >
        <Modal.Section >
          {agreementSentToEmail && <p>
            We sent you another email with the subject line "Storage Agreement".
            Please open the link in the email to continue.
          </p>}
        </Modal.Section>

      </Modal>
    ),
    //==================================
    pageMarkup: StorageAgreementCompleted ? (
      <Badge size="large" tone="success" icon={CircleTickMinor}>Storage Agreement signed</Badge>
    ) : <>
      {!agreementSentToEmail
        ? <ButtonAwait
          onClick={onClickSignStorageAgreement}
          disabled={agreementSentToEmail}
        >{`Click here to sign your storage agreement`}</ButtonAwait>
        : <p>
          We sent you another email with the subject line "Storage Agreement".
          Please click the link in the email to continue.
        </p>}
    </>,
    //==================================
    closeModal: onClose,
  };
}

function useBasicInfoMarkup(app: AppService, afterSave: () => Promise<void>) {
  const status = app.status;
  ok(status);
  const [name, setName] = useState(status.Name ?? "");
  const [address, setAddress] = useState(status.Address ?? "");
  const [phone, setPhone] = useState(status.Phone ?? "");
  const [nameInvalid, setNameInvalid] = useState(false);
  const onSave = async () => {
    if (!name) { setNameInvalid(true); return; }
    await app.postAppState("basic-info", { Name: name, Address: address, Phone: phone });
    await app.updateStatus();
    await afterSave();
  };
  return <>
    <InlineStack gap="400">
      <div style={{ flex: "1" }}>
        <TextField
          label="Name"
          requiredIndicator
          autoComplete="yes"
          error={nameInvalid ? "Name is required" : undefined}
          value={name}
          onChange={setName}
          onBlur={() => { setNameInvalid(!name); }}
        />
      </div>
    </InlineStack>
    <InlineStack gap="400">
      <div style={{ flex: "1" }}>
        <TextField label="Address" autoComplete="yes" value={address} onChange={setAddress} />
      </div>
    </InlineStack>
    <InlineStack gap="400">
      <div style={{ flex: "1" }}>
        <TextField label="Phone" autoComplete="yes" value={phone} onChange={setPhone} />
      </div>
    </InlineStack>
    <InlineStack align="space-between" gap="400">
      <div></div>
      <ButtonAwait variant="primary" onClick={onSave}>Save</ButtonAwait>
    </InlineStack>
  </>;
}

function useFormUrl(card: useSimpleValueStore<any>) {
  const app = useAngular().get(AppService);
  const [curFormUrl, setFormUrl] = useState("");
  const [curFormUrlLoading, setFormUrlLoading] = useState(false);
  useAsyncEffect(async () => {
    // console.log("loadFormUrl", !!app.status, !!app.idToken);

    if (!app.status) return;

    if (app.paymentFormTokenId) return;

    if (curFormUrl || curFormUrlLoading) return;

    if (!card.editing) return;

    setFormUrlLoading(true);

    const [error, res] = await app.postAppState("form-url", { redirect: location.href }).then(e => [undefined, e], e => [e, undefined]);

    const { formUrl } = res ?? {};

    setFormUrlLoading(false);

    if (formUrl && !error) setFormUrl(formUrl);

  }, undefined, undefined, [card.editing, app.status, app.paymentFormTokenId, curFormUrl, curFormUrlLoading]);
  return curFormUrl;
}

function formatSpacing(input: string, spacing: number) {
  return input.split(" ").join("").replace(new RegExp(`(.{${spacing}})`, "g"), "$1 ").trim();
}
