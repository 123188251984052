import { useCustomTable } from "../../../branch/src/app/react-app/tables/useCustomTable";
import { getCustomerActualTransactions, SelectTypeTree } from "common";
import { useAngular, useAsyncEffect, useDispatch } from "react-utils";
import { AppError, AppService } from "./service-app";
import { TableViewClass } from "../../../branch/src/app/react-app/tables/table-views";
import { SelectTable, useTableListTree } from "../../../branch/src/app/react-app/tables/SelectTable";
import { useState } from "react";
import { DataListColumn } from "../../../branch/src/app/react-app/utils";
import { BlockStack, InlineError, Modal, Page, Text } from "@shopify/polaris";
import { useSimpleValueFields } from "./react-app/useSimpleValueFields";
// import DineroFactory from "dinero.js";
getCustomerActualTransactions

const invoiceView = TableViewClass.makeClientView("CustomerLedger", {
  AND: [],
  list: (x: SelectTypeTree<"CustomerLedger">) => {
    return [
      { key: x.id.__, hidden: true, },
      x.line.Date.__,
      x.line.invoiceLine.item.ItemName.__,
      // x.line.invoiceLine.item.ItemType.__,
      x.line.invoiceLine.rental.unit.Name.__,
      // x.line.invoiceLine.rental.unit.unitType.Name.__,
      // x.line.paymentLine.PaymentStatus.__,
      // x.line.paymentLine.PaymentFee.__,
      x.line.invoiceLine.paidOn.__,
      x.Amount.__,
    ] as const;
  },
  title: "Customer Ledger",
  key: "list",
})

const paymentView = TableViewClass.makeClientView("CustomerLedger", {
  AND: [],
  list: (x: SelectTypeTree<"CustomerLedger">) => {
    return [
      { key: x.id.__, hidden: true, },
      x.line.Date.__,
      // x.line.invoiceLine.paidOn.__,
      // x.line.invoiceLine.item.ItemName.__,
      // x.line.invoiceLine.item.ItemType.__,
      // x.line.invoiceLine.rental.unit.Name.__,
      // x.line.invoiceLine.rental.unit.unitType.Name.__,
      x.line.paymentLine.PaymentStatus.__,
      // x.line.paymentLine.PaymentFee.__,
      x.Amount.__,
    ] as const;
  },
  title: "Customer Ledger",
  key: "list",
})

export default function () {
  const app = useAngular().get(AppService);

  const invoiceTable = useTableListTree("CustomerLedger", invoiceView);
  const paymentTable = useTableListTree("CustomerLedger", paymentView);
  const [curBalance, setBalance] = useState(0);

  const [refreshTable, setRefreshTable] = useState({});

  const [showPayment, setShowPayment] = useState(false);

  const openPayment = useDispatch(() => {
    setShowPayment(true);
  });

  const closePayment = useDispatch(() => {
    setShowPayment(false);
    setRefreshTable({});
  });

  const { loading } = useAsyncEffect(async () => {
    const balance = await app.postAppState("balance", {}, true);
    const history = await app.postAppState("history", {}, true);
    invoiceTable.setValue({ action: "reset", newValue: history?.filter(e => e.line.invoiceLine) });
    paymentTable.setValue({ action: "reset", newValue: history?.filter(e => e.line.paymentLine) });
    setBalance(balance ?? 0);
  }, undefined, undefined, [refreshTable]);
  return (
    <Page
      title={"Balance: " + DataListColumn.textCubesDinero(`${curBalance}`)}
      primaryAction={{
        content: 'Make A Payment',
        onAction: openPayment
      }}
      fullWidth
    >
      <BlockStack>
        <Text as="p" variant="headingMd">Charges</Text>
        <SelectTable
          cols={invoiceTable.cols}
          rows={invoiceTable.rows}
          idcol={invoiceTable.idcol}
          firstSort={invoiceTable.arraySort[0]}
          loading={loading}
          emptyState="No transactions found"

        />
        <Text as="p" variant="headingMd">Payments</Text>
        <SelectTable
          cols={paymentTable.cols}
          rows={paymentTable.rows}
          idcol={paymentTable.idcol}
          firstSort={paymentTable.arraySort[0]}
          loading={loading}
          emptyState="No transactions found"

        />
      </BlockStack>
      {showPayment ? <MakePaymentModel close={closePayment} balance={curBalance} /> : null}
    </Page>
  )
}

class useSimpleValueFields2 extends useSimpleValueFields<{ Amount: string }> {
  public saving: boolean = false;
  public editing: boolean = true;
}

export function MakePaymentModel({ close, balance }: { close: () => void; balance: number | null; }) {
  const { get } = useAngular();
  const app = get(AppService);

  const [sending, setSending] = useState(false);
  const [complete, setComplete] = useState(false);
  const [failure, setFailure] = useState("");

  const store = new useSimpleValueFields2({ Amount: "" });

  let value = +store.curValue["Amount"].slice(1).split(".").join("") || 0;
  if (!Number.isFinite(value) || Number.isNaN(value)) value = 0;
  // const amount = DineroFactory({ amount: value });
  // const ccfee = amount.divide(1 - CONSTANTS.CCFEE_PERCENT / 100).subtract(amount);
  const total = value; // app.isCard ? amount.divide(1 - CONSTANTS.CCFEE_PERCENT / 100) : amount;

  const makePayment = useDispatch(async () => {
    if (value < 0) return { toolow: true }
    if (value === 0) return { required: true };
    if (sending) return { sending: true };

    setSending(true);
    const [error, { success, reason } = { success: false, reason: "A server error occurred" }] = await app.postAppState("amount", { amount: total }).try();
    setSending(false);

    if (error instanceof AppError) {
      setFailure(error.body);
    } else if (error) {
      setFailure("Web page not available");
    } else if (!success) {
      setFailure(reason);
    } else if (success) {
      setComplete(true);
    }
  });

  const balanceString = DataListColumn.textCubesDinero(balance);


  return (
    <Modal
      open={true}
      onClose={close}
      title="Make A Payment"
      secondaryActions={(complete || sending) ? [] : [{ content: 'Close', onAction: close }]}
      primaryAction={{ content: 'Ok', onAction: complete ? close : makePayment, loading: sending }}
    >
      <Modal.Section>
        <BlockStack gap="300">
          {!complete && <>
            <Text as="p" variant="bodyMd">Balance: {balanceString}</Text>
            {store.TextField({
              key: "Amount",
              title: "Amount",
              validate: (value) => value ? undefined : "Required", required: true,
              onChangeMap: input => {
                if (!input) return "";
                input = input.split("").filter(e => /[0-9]/.test(e)).join("").padStart(3, "0");
                while (input.length > 3 && input[0] === "0") input = input.slice(1);
                return "$" + input.slice(0, input.length - 2) + "." + input.slice(input.length - 2);
              }
            })}
            {app.isCard && <Text as="p" variant="bodyMd">Credit Card Fees are not charged</Text>}
            <Text as="p" variant="headingMd">Total: {DataListColumn.textCubesDinero(total)}</Text>
            {failure && <InlineError message={failure} fieldID="error" />}
          </>}
          {complete && <>
            <Text as="p" variant="headingMd">Total: {DataListColumn.textCubesDinero(total)}</Text>
            <Text as="p" variant="bodyMd">Payment accepted. Thank you.</Text>
          </>}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

}
