import { Modal } from "@shopify/polaris/index";
import { useCallback } from "react";
import { useAngular, useAsyncEffect } from "react-utils";
import { AppService } from "./service-app";
import { useNavigate } from "react-router";

export default function FinishTokenId() {
  const { get } = useAngular();
  const navigate = useNavigate();
  const app = get(AppService);

  const { loading: loadingToken, result: tokenResult } = useAsyncEffect(async () => {
    if (!app.paymentFormTokenId) return;
    let { success, reason, } = await app.postAppState("token-id", { tokenId: app.paymentFormTokenId })
      .then(e => (e ?? { success: false, reason: "Something went wrong" }))
      .catch(e => ({ success: false, reason: "A server error occured" }));

    app.paymentFormTokenId = null;
    success = success ?? false;
    reason = reason ?? "";
    return { success, reason };
  });

  useAsyncEffect(async () => {
    if (!tokenResult?.success) return;
    await app.updateStatus();
  }, undefined, undefined, [tokenResult]);

  const close = useCallback(() => { navigate("/billing"); }, [navigate]);

  return <>
    <Modal
      open={true}
      onClose={() => { }}

      title="Update Payment Method"
      primaryAction={{ content: 'Ok', onAction: close, loading: loadingToken }}
    >
      <Modal.Section>
        <p>{loadingToken ? "Please wait while we process your information." : tokenResult?.success ? "Thank you. Your payment information has been saved!" : "Error: " + tokenResult?.reason}</p>
        <p>{loadingToken ? null : tokenResult?.success ? null : "You may need to contact your account representative to help you resolve this error."}</p>
      </Modal.Section>
    </Modal>
  </>

}