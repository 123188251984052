import { useLayoutEffect } from "react";
import { useRef, useState, useSyncExternalStore } from "react";
import { MessageService, useAngular } from "react-utils";
import { useDispatch } from "./useDispatch";
import { useSimpleValueFields } from "./useSimpleValueFields";

export function reducerKeyValueMap<K extends string, V>(state: Record<K, V>, action: { key: K; value: V; } | "clear") {
  if (action === "clear") return {};
  const { key, value } = action;
  return { ...state, [key]: value };
}


// /**
//  * 
//  * @param initValue This value is inducted via useDispatch, which saves it's closure via useLayoutEffect.
//  * @param saveValueCB 
//  * @returns 
//  */
// export function useSimpleValueStore<T extends Record<string, string>>(initValue: T, saveValueCB: (value: T) => Promise<void>) {

//   const { get } = useAngular();
//   const ms = get(MessageService);
//   const initRef = useRef(initValue);

//   const [saving, setLoading] = useState(false);

//   const valueRef = useRef<T>(initValue);
//   const storeRef = useRef<() => void>(() => { });
//   const savedValue = useSyncExternalStore<T>(cb => { storeRef.current = cb; return () => { }; }, () => valueRef.current);

//   const [editing, setEdit] = useState(false);
//   // const [curValue, setValue] = useState(initValue);
//   const [curValid, setValid] = useState(true);

//   // const changedFields = useRef<Record<string, boolean>>({});
//   // const blurredFields = useRef<Record<string, boolean>>({});

//   // const [curChangedFields, setChangedFields] = useReducer(reducerKeyValueMap<string, boolean>, {});
//   // const [curBlurredFields, setBlurredFields] = useReducer(reducerKeyValueMap<string, boolean>, {});


//   const editValue = useDispatch(() => {
//     setEdit(true);
//   })

//   const saveValue = useDispatch(async () => {
//     setLoading(true);
//     await saveValueCB(store.curValue);
//     valueRef.current = store.curValue;
//     setLoading(false);
//     setEdit(false);
//     ms.add({ severity: "success", summary: "Saved", detail: "Your changes have been saved" });
//     storeRef.current();
//   });

//   const cancelValue = useDispatch(() => {
//     setEdit(false);
//     store.setValue(valueRef.current);
//   })

//   const resetValue = useDispatch(() => {
//     initRef.current = { ...initValue };
//     store.setValue({ ...initRef.current });
//     store.setChangedFields("clear");
//     store.setBlurredFields("clear");
//   });

//   const action = useDispatch((action: "edit" | "cancel" | "save" | "reset" | { key: keyof T; value: string; }) => {
//     if (action === "edit") { editValue(); }
//     if (action === "cancel") { cancelValue(); }
//     if (action === "save") { saveValue(); }
//     if (action === "reset") { resetValue(); }
//     if (typeof action === "object") {
//       if (typeof action.key !== "string") throw new Error("key must be a string");
//       store.setValue(curValue => ({ ...curValue, [action.key]: action.value }));
//     }
//   });

//   const store = new SimpleValueStore(editing, saving, initValue, savedValue, action);

//   useLayoutEffect(() => { setValid(store.valid); }, [store.valid]);

//   useLayoutEffect(() => { if (!editing) valueRef.current = store.curValue; }, [store.curValue, editing]);

//   return store;

// }

/** Do not memoize this class. The constructor is a react hook and contains use* hook calls */
export class useSimpleValueStore<T extends { readonly [K: string]: string }> extends useSimpleValueFields<T> {
  public editing: boolean;
  public saving: boolean;
  public savedValue: T;
  
  action;
  editValue;
  saveValue;
  resetValue;
  cancelValue;
  constructor(
    initValue: T,
    saveValueCB: (value: T) => Promise<void>,
  ) {

    super(initValue);

    const [editing, setEdit] = useState(false);
    this.editing = editing;
    const [saving, setLoading] = useState(false);
    this.saving = saving;

    const { get } = useAngular();
    const ms = get(MessageService);
    const initRef = useRef(initValue);

    const valueRef = useRef<T>(initValue);
    const storeRef = useRef<() => void>(() => { });
    const savedValue = useSyncExternalStore<T>(cb => { storeRef.current = cb; return () => { }; }, () => valueRef.current);
    const [curValid, setValid] = useState(true);

    this.editValue = useDispatch(() => { setEdit(true); })

    this.saveValue = useDispatch(async () => {
      setLoading(true);
      await saveValueCB(this.curValue);
      valueRef.current = this.curValue;
      setLoading(false);
      setEdit(false);
      ms.add({ severity: "success", summary: "Saved", detail: "Your changes have been saved" });
      storeRef.current();
    });

    this.cancelValue = useDispatch(() => {
      setEdit(false);
      this.setValue(valueRef.current);
    })

    this.resetValue = useDispatch(() => {
      initRef.current = { ...initValue };
      this.setValue({ ...initRef.current });
      this.setChangedFields("clear");
      this.setBlurredFields("clear");
    });

    this.action = useDispatch((action: "edit" | "cancel" | "save" | "reset" | { key: keyof T; value: string; }) => {
      if (action === "edit") { this.editValue(); }
      if (action === "cancel") { this.cancelValue(); }
      if (action === "save") { this.saveValue(); }
      if (action === "reset") { this.resetValue(); }
      if (typeof action === "object") {
        if (typeof action.key !== "string") throw new Error("key must be a string");
        this.setValue(curValue => ({ ...curValue, [action.key]: action.value }));
      }
    });

    const { valid } = this;

    useLayoutEffect(() => { setValid(valid); }, [valid]);

    const { curValue } = this;

    useLayoutEffect(() => { if (!editing) valueRef.current = curValue; }, [curValue, editing]);
  }


}